.playlistsOptions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .openOptions {
    transition: all 0.2s ease-out;
    transform: translateX(0);
    opacity: 1;
  }

  .closeOptions {
    transition: all 0.2s ease-in-out;
    transform: translateX(100%);
    opacity: 0;
  }
}
