.miniPlayerContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  grid-row-gap: 60px;
}

.miniPlayerCard {
  .miniPlayer {
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-out;
    overflow: hidden;
    border-radius: 5px;
    border-bottom:2px solid  rgba(0, 0, 0, 0.7);
    aspect-ratio: 3/2;
    height: fit-content;

    .web-img {
      aspect-ratio: 3/2;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-out;
      object-fit: cover;
    }

    .ios-img {
      aspect-ratio: 3/2;
      border-radius: 5px;
      width: 100%;
      transition: all 0.3s ease-out;
      object-fit: cover;
    }

    .watch-img {
      aspect-ratio: 3/2;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-out;
      object-fit: cover;
    }

    .customVideoPlayIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 10px;
      height: 55px;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-out;
    }
  }

  &:hover {
    .miniPlayer {
      border-radius: 5px;
      img {
        transform: scale(1.05);
        border-radius: 5px;
      }
    }
  }
}

@media (min-width: 1450px) {
  .miniPlayerContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}
