.downloadModal {
  .myloginheader {
    position: relative;
    padding: 34px 0 45px;
  }

  .header-bg {
    position: absolute;
    top: -15px;
    right: -15px;
    bottom: 0;
  }

  .container {
    max-width: 1156px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
  }

  .flex-row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
  }

  .link-div {
    display: flex;
    align-items: center;
  }

  .flex-table {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .title-header {
    margin-bottom: -3px;
    margin-left: 20px;
    font-family: var(--font-family-bold);
    font-size: 18px;
    letter-spacing: 0;
  }

  /* main */

  .text-centered {
    margin: 0 auto;
    text-align: center;
    justify-content: center;
  }

  .form-container-login {
    margin: 12vh auto 12vh;
    width: 100%;
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-container-password {
    margin: 12vh auto 12vh;
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sign-up-form2 {
    width: 640px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sign-up-form2up {
    width: 640px;
  }

  .form-content {
    margin: 40px 0 32px;
    gap: 20px;
  }

  .form-input {
    gap: 8px;
  }

  .resend-pin-text {
    font-family: "Lexend Deca", sans-serif !important;
    font-size: 16px !important;
  }

  .PrevButtonLogin {
    font-size: 16px;
    font-family: "Lexend Deca", sans-serif;
    line-height: 20px;
    /* color: #090324; */
    color: #344767;
    font-weight: 400 !important;
    margin-top: 10px;
    /* margin: 0 !important; */
    background: transparent;
    /* font-family: inherit; */
  }

  .form-input > label {
    font-family: var(--font-family-bold);
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0;
  }

  .form-input > input,
  .form-input > div > input {
    padding: 16px 20px;
    width: 100%;
    font-family: var(--font-family-regular);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    border: 1px solid #4a59ff;
    border-radius: 10px;
  }

  .form-input > input::placeholder,
  .form-input > input::-ms-input-placeholder,
  .form-input > input::-ms-input-placeholder {
    color: #9093b5;
  }

  .submit_btn_signup {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title-form {
    font-size: 24px;
  }

  .title-form-div {
    margin-left: 85px !important;
  }

  .form-submit-btn {
    width: 100%;
    background: #4a59ff;
    color: #fefefe;
    font-family: var(--font-family-bold);
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0;
    padding: 20px 24px;
    border-radius: 10px;
    border: none;
  }

  .form-submit-btn2 {
    width: 73%;
    background: #4a59ff;
    color: #fefefe;
    font-family: var(--font-family-bold);
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0;
    padding: 20px 24px;
    border-radius: 10px;
    border: none;
  }

  .input-phone-container {
    position: relative;
    width: 100%;
  }

  .input-phone-container::after {
    position: absolute;
    top: 18px;
    left: 20px;
    right: 12px;
    /* content: url("../img/usa.svg"); */
    width: 38px;
  }

  .input-phone-container::before {
    background-color: #eef0f4;
    content: "";
    height: 40px;
    width: 1px;
    left: 78px;
    top: 8px;
    position: absolute;
  }

  .react-tel-input .form-control {
    border: 1px solid #4a59ff;
    border-radius: 10px;
  }

  .form-input-number {
    height: 65px;
    width: 65px !important;
    border-radius: 10px;
    background: #fefefe;
    border: 1px solid #4a59ff;
  }

  .form-input-number::-webkit-inner-spin-button,
  .form-input-number::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-input-number[type="password"] {
    -moz-appearance: textfield;
    font-size: 24px;
    text-align: center;
  }

  input[id="code-verification"] {
    display: none;
  }

  .verification-grid {
    gap: 15px;
  }

  .label-email {
    color: black !important;
  }

  .label-firstname {
    color: black !important;
  }

  .label-lastname {
    color: black !important;
  }

  .label-phonenumber {
    color: black !important;
  }

  .form-text {
    margin: 3px 0 20px;
    color: #394055;
    font-size: 16px;
    font-family: var(--font-family-book);
  }

  .form-content.flex-table.verification-status {
    margin: 0;
  }

  .flex-row.verification-grid {
    margin-bottom: 25px;
  }

  .back-btn {
    margin-top: 20px;
  }

  /* footer */
  footer > div.container.flex-table {
    gap: 130px;
  }

  .footer-text,
  .footer-link {
    font-family: var(--font-family-regular);
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;
    color: #020c2d;
    text-decoration: none;
    margin: 0;
  }

  .btn-for-signup {
    margin-top: 25px;
  }

  .link-hide {
    color: #eef0f4 !important;
  }

  .footer-bottom-content {
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 35px;
  }

  .footer-bottom-content > div.flex-row {
    gap: 30px;
  }

  .footer-top-content {
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer-section-form {
    gap: 48px;
    padding-right: 10px;
    max-width: 50%;
  }

  .footer-section-form > img {
    height: 30px;
    width: 147px;
  }

  .footer-section-list {
    width: 50%;
    justify-content: space-between;
  }

  .footer-section-list > div > ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .footer-list-title {
    font-family: var(--font-family-bold);
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: #4a59ff;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .footer-list {
    gap: 20px;
  }

  .footer-list-item {
    font-family: var(--font-family-regular);
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;
  }

  .footer-form {
    background-color: #ffffff;
    box-shadow: 0 4px 15px 0 #aaaab940;
    border-radius: 10px;
  }

  .input-form-email {
    min-width: 60px;
    padding: 22px 0 20px 25px;
    outline: none;
    border: none;
    border-radius: 10px;
    color: #020c2d;
    font-family: var(--font-family-regular);
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;
  }

  .input-form-email::placeholder {
    font-family: var(--font-family-regular);
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;
    color: #020c2d;
    opacity: 0.2;
  }

  .submit-email-btn {
    background: #4a59ff;
    border-radius: 10px;
    width: 152px;
    height: 50px;
    font-family: var(--font-family-bold);
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0;
    color: #ffffff;
    border: none;
    outline: none;
    margin: 4px;
  }

  @media screen and (max-height: 800px) {
    /* Your CSS rules for screens with height less than or equal to 800 pixels */
    .footer-minify {
      /* padding-top: 50px; */
      position: relative;
      padding-bottom: 6px;
      width: 100%;
    }
  }
}
