.nav-link,
.nav-link-light {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  transition: background-color 0.3s ease-in-out;
  line-height: 2.75;
}

.nav-link:hover,
.nav-link.active {
  background-color: #4a59ff;
}

.nav-link-light:hover,
.nav-link-light.active {
  background-color: lighten(#4a59ff, 10);
}
