.player {
  height: 410px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .MuiSelect-icon .MuiSelect-iconOutlined {
    color: white !important;
  }

  .replayButton {
    cursor: pointer;
    transition: all 0.2s ease-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
    padding: 0.8em 1.5em;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .show {
    transform: translate(-50%, -50%) scale(1);
  }

  .hide {
    transform: translate(-50%, -50%) scale(0);
  }
}

.input-label-users {
  padding: 4px 0 13px !important;
  color: white !important;
}

.screenshotsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-height: 50em;
  overflow: scroll;

  .imgContainer {
    transition: all 0.3s ease-out;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 5px;

    .web-img {
      aspect-ratio: 16/9;
      cursor: pointer;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-out;
      object-fit: cover;
    }

    .ios-img {
      aspect-ratio: 16/9;
      cursor: pointer;
      border-radius: 5px;
      width: 100%;
      transition: all 0.3s ease-out;
      object-fit: cover;
    }

    .watch-img {
      aspect-ratio: 16/9;
      cursor: pointer;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-out;
      object-fit: cover;
    }
  }
}
