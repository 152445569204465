*::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

*::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

*::-webkit-scrollbar-corner {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #b6b8cd !important;
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  overflow-x: hidden;
}

.Mui-disabled {
  color: gray !important;
}

video::-webkit-media-controls-mute-button {
  opacity: 0;
}

video {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  outline: none;
}

.slick-list {
  margin: 0 -5px;
}

.slick-slide > div {
  padding: 0 5px;
}

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
  color: #4a59ff !important;
}

.videoGrid {
  display: grid;
  grid-template-columns: 350px calc(100% - 360px);
  gap: 10px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

// *::selection {
//   background-color: #4a59ff;
// }

.MuiAutocomplete-popupIndicator {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-10%);
}

.MuiBreadcrumbs-separator {
  margin: 4px !important;
}
