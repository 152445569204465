.suggestions {
  max-width: 400px;
  margin-top: 0.5em;

  .miniPlayerContainer-suggestions {
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding-bottom: 10em;
    padding-inline: 1em;

    .miniPlayer {
      position: relative;
      cursor: pointer;
      transition: all 0.3s ease-out;
      overflow: hidden;
      aspect-ratio: 16/9;
      border-radius: 5px;
      flex: 2;

      .web-img {
        border-radius: 5px;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-out;
        object-fit: cover;
      }

      .ios-img {
        border-radius: 5px;
        width: 100%;
        transition: all 0.3s ease-out;
        object-fit: cover;
      }

      .watch-img {
        border-radius: 5px;
        width: 100%;
        height: 100%;

        transition: all 0.3s ease-out;
        object-fit: cover;
      }

      .customVideoPlayIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 10px;
        height: 55px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-out;
      }

      &:hover {
        border-radius: 5px;
        .customVideoPlayIcon {
          background-color: #a12e2eb3;
        }
        img {
          transform: scale(1.05);
          border-radius: 5px;
        }
      }
    }
  }
}

// TODO: media query gia mikroteres othonoes, na mikrainei to box?
