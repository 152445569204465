.search-container {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 35px;
  padding: 2px;
  width: 100%;
  min-width: 230px;

  input[type="text"] {
    outline: none;
    flex-grow: 1;
    border: none;
    font-size: 16px;
    padding: 10px;
    background-color: transparent;
  }
}
